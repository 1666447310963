export const red = '#c60000';
export const mediumRed = '#ff6161';
export const lightRed = '#fff2f2';

export const gold = '#b8860b';
export const silver = 'silver';
export const bronze = '#aa6b39';

export const green = '#7FFF00';
export const yellow = '#ffc107';
